<template>
  <div class="main">
    <div class="container">
      <div class="judges_detail">
        <div class="judges_pic">
          <div class="name">{{detail.title}}</div>
          <div class="time">{{detail.year}}</div>
        </div>
        <div class="judges_info">
			<div class="video_warp" v-if="detail.video && detail.video.length > 0">
				<video v-for="(item, index) in detail.video" :src="item" style="width: 100%;height: auto;margin-bottom: 20px;" controls preload></video>
			</div>
			
          <div class="img_warp" v-if="detail.detail_img && detail.detail_img.length > 0">
			  <img v-for="(item, index) in detail.detail_img" :src="item" />
		  </div>
		  <div class="info">
			  <p><span class="bold">Awards: </span><span>{{detail.awards}}</span></p>
			  <p><span class="bold">Category: </span><span>{{detail.category}}</span></p>
			  <p><span class="bold">Entry title: </span><span></span>{{detail.title}}</p>
			  <p><span class="bold">Brand owner: </span><span>{{detail.brand}}</span></p>
			  <p><span class="bold">Major contributive companies: </span><span>{{detail.company}}</span></p>
		  </div>
		  
          <div class="text" style="padding-top: 40px;">
			<p><span class="bold">Summary of works:</span></p>
            <div class="content_text" v-html="detail.summary"></div>
          </div>
		  <div class="backBtn" @click="pageBack"></div>
        </div>
		
      </div>
    </div>
  </div>
</template>

<script>
import { CASEDETAIL } from '@/api/awards'
export default {
  name: 'JudgesDetail',
  components: {},
  data() {
    return {
		detail: {}
	};
  },
  created() {
	  this._initPage()
  },
  methods: {
	  pageBack() {
	    this.$router.go(-1);
	  },
	  async _initPage() {
		  const { id } = this.$route.params
		  const { data } = await CASEDETAIL({id})
		  console.log(data)
		  if(data) {
			  this.detail = {
				  ...data,
				  detail_img: data.detail_img ? data.detail_img.split(',') : [],
				  video: data.video ? data.video.split(',') : [],
				  summary: this._formatBr(data.summary)
			  }
			  console.log(this.detail)
		  }
	  },
	  _formatBr(str) {
		  return str.replace(/\n/g, '<br />')
	  }
  },
};
</script>
<style lang="scss" scoped>
.judges_detail {
  margin-top: 50px;
  display: flex;
  .bold{
	  font-weight: bold;
	  padding-right: 5px;
  }
  .judges_pic {
    flex: 0 0 200px;
    width: 200px;
    line-height: 26px;
    .img {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
    .name {
      margin: 0px auto 20px;
      font-size: 26px;
      line-height: 26px;
	  font-family: 'SiemensSansBold';
    }
    .company {
      font-size: 20px;
    }
    .title {
      font-size: 20px;
      font-style: italic;
    }
    .time {
      font-size: 20px;
	  font-family: 'SiemensSansBold';
    }
  }
  .judges_info {
    margin-left: 50px;
    flex: 1;
    img {
      display: block;
      width: 100%;
      margin-bottom: 30px;
	  
    }
    .text {
      // font-size: 18px;
      line-height: 30px;
	  &.content_text{
		  font-size: 18px;
	  }
    }
  }
}
@media (max-width: 950px) {
  .judges_detail {
    display: block;
    .judges_pic {
      margin-bottom: 50px;
      width: 100%;
    }
    .judges_info {
      margin-left: 0px;
      flex: 1;
      img {
        display: block;
        width: 100%;
        margin-bottom: 30px;
      }
      .text {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}
</style>
